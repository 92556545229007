import {useEffect} from 'react';
import {useLocation, withRouter} from 'react-router-dom';

import {addThisRefresh} from '../../utils/componentHelpers';

export default function AddThisRefresh() {
  const {pathname} = useLocation();

  useEffect(() => {
    addThisRefresh();
  }, [pathname]);

  return null;
}
