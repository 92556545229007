import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import ReferenceLink from './ReferenceLink';
import {clickPrevent} from '../../utils/componentHelpers';

const A = css`
  color: #222;
  font-family: Muli;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #b565a7;
  }

  span {
    background: #b565a7;
    border-radius: 5px;
    height: 7px;
    margin-top: -3.5px;
    /* margin-left: 16px; */
    position: absolute;
    right: 0;
    top: 50%;
    transition: background-color 0.2s ease-in-out;
    width: 7px;
  }
`;

const RefLink = styled(ReferenceLink)`
  ${A}
`;

const HashLink = styled.a`
  ${A}
  cursor: pointer;
`;

const HeaderLink = ({tag: Tag, hasDot, className, link, text, children}) => (
  <Tag className={className}>
    {link ? <RefLink reference={link}>
      {hasDot ? <span /> : null}
    </RefLink> : <><HashLink onClick={clickPrevent()} href="#">{text}{hasDot ? <span /> : null}</HashLink>{children}</>}
  </Tag>
);

HeaderLink.propTypes = {
  link: PropTypes.object,
  tag: PropTypes.any,
  hasDot: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.any
};

HeaderLink.defaultProps = {
  tag: 'div'
};

export default HeaderLink;
