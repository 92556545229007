import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import Container from '../layout/Container';

const NotFoundWrap = styled.div`

`;

const Content = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
  margin: 30px auto;
  max-width: 500px;
  padding: 15px 0;
  text-align: center;

  h2 {
    margin: 0;
  }

  p {
    margin: 10px 0;
  }

  a {
    color: #B565A7;
  }
`;

const NotFound = () => (
  <NotFoundWrap>
    <Container>
      <Content>
        <h2>OOOPS! 404</h2>
        <p>Sorry, but the page you are looking for has not been found.</p>
        <Link to="/">
          Go Back
        </Link>
      </Content>
    </Container>
  </NotFoundWrap>
);

export default NotFound;
