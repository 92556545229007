import React, {Component, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import contentful from '../../services/contentful';
import {device} from '../../styles/responsive';
import AuthorBanner from '../containers/AuthorBanner';
import PostPreview from '../pieces/PostPreview';
import Container from '../layout/Container';
import {addThisRefresh} from '../../utils/componentHelpers';
import {usePosts, useErrorById, useAuthorById} from '../data/Store';
import Archive from '../containers/Archive';
import Head from '../layout/Head';
import Loading from '../pieces/Loading';

const ContentWrap = styled.div`
  margin-top: 80px;
`;

const Post = styled(PostPreview)`
  margin-bottom: 100px;
`;

const authorId = '7vBSJQUCYPzHvPs3hmWilt';

const Home = ({match: {params: {page}}}) => {
  const [posts, actions] = usePosts();
  const [author] = useAuthorById(authorId);
  const [error] = useErrorById('home');

  useEffect(
    () => {
      if (!posts) {
        actions.postsGet();
        actions.setLoadingForSlug('mainContent', true);
      }

      if (!author) {
        actions.authorGet(authorId);
      }
    }
  );

  actions.setLoadingForSlug('mainContent', false);

  if (!posts || !author) {
    return (
      <Loading/>
    );
  }

  return (
    <>
      <Head/>
      {page > 1 ? null : (
        <AuthorBanner
          image={author && author.fields.image ? author.fields.image.fields.file.url : null}
          blurb={author ? author.fields.homeBlurb : ''}
          instagram={author ? author.fields.instagram : ''}
          facebook={author ? author.fields.facebook : ''}
        />
      ) }
      <ContentWrap>
        <Container>
          <Archive key={'home'} page={parseInt(page, 10)} />
        </Container>
      </ContentWrap>
    </>
  );
};

Home.propTypes = {
  match: PropTypes.object
};

export default Home;
