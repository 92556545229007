import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import Image from '../pieces/Image';
import Categories from './Categories';
import {device} from '../../styles/responsive';
import Wysiwyg from './Wysiwyg';

const renderers = {
  image: Image
};

const Header = styled.div`
  padding: 40px 15px 0;
  margin: 0 0 30px;
`;

const HeaderCategories = styled(Categories)``;

const Title = styled.h1`
  font-size: 28px;
  margin: 13px 0 17px;
  text-align: center;

  @media ${device.laptop} {
    font-size: 40px;
  }
`;

const Meta = styled.div`
  margin: -10px 0 20px 0;
  text-align: center;
`;

const Comments = styled.a`
  color: #aaa;
  text-transform: uppercase;
`;

const Hero = styled.div`
  margin: 0 0 15px;
`;

const Body = styled.div`
  background-color: #fff;
  padding: 0 15px 5px;

  ul,
  ol {
    margin-bottom: 1.25em;
    margin-top: 1.25em;
  }
`;

const PostContent = ({title, heroImage, body, link, category}) => (
  <>
    <Header>
      {category ? <HeaderCategories categories={[{name: category.fields.name, slug: category.fields.slug}]} /> : null}
      <Title>{link ? <Link to={link}>{title}</Link> : title}</Title>
      {/* <Meta>
            <Comments href="#comments">5 Comments</Comments>
          </Meta> */}
    </Header>
    {heroImage ? (
      <Hero>
        <Image
          src={heroImage.fields.file.url}
          height={heroImage.fields.file.details.image.height}
          width={heroImage.fields.file.details.image.width}
        />
      </Hero>
    ) : null}

    <Body>
      <Wysiwyg content={body} />
    </Body>
  </>
);

PostContent.propTypes = {
  title: PropTypes.string,
  heroImage: PropTypes.object,
  body: PropTypes.string,
  link: PropTypes.string,
  category: PropTypes.object
};

export default PostContent;
