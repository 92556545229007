import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Headroom from 'react-headroom';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListUl} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

import contentful from '../../services/contentful';
import Container from './Container';
import Hl from '../pieces/HeaderLink';
import {device} from '../../styles/responsive';
import {listUnstyled} from '../../styles/mixins';
import {useMenuById} from '../data/Store';

const FixedLogoWidth = 116;

const Dropdown = styled.div`
  margin: 0 -3%;

  @media ${device.laptop} {
    background-color: rgba(255, 255, 255, 0.98);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
    height: 0;
    left: auto;
    margin: 0;
    perspective: 2000px;
    position: absolute;
    min-width: 180px;
    top: 100%;
    transition: all 0.25s ease, visibility 0s 0.25s;
    visibility: hidden;
  }
`;

const DropdownInner = styled.div`
  @media ${device.laptop} {
    opacity: 0;
    transform: rotateX(-20deg);
    transform-origin: 50% -50px;
    transition: all 0.25s ease, visibility 0s 0.25s;
    visibility: hidden;
    will-change: opacity, transform, visibility;
  }
`;

const HeaderLink = styled(Hl)`
  @media ${device.laptop} {
    &:hover ${Dropdown}, a:hover + ${Dropdown} {
      height: auto;
      opacity: 1;
      visibility: visible;
      transform: rotateX(0deg);
      transition: all 0.25s ease, opacity 0s;
    }
    &:hover ${DropdownInner}, a:hover + ${DropdownInner} {
      opacity: 1;
      visibility: visible;
      transform: rotateX(0deg);
      transition: all 0.25s ease, opacity 0s;
    }
  }
`;

const HeaderInner = styled.div`
  align-items: center;
  /* background-color: #fff;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2); */
  display: flex;
  height: ${props => props.height}px;
  justify-content: center;
  postition: relative;
  transition: height 0.2s ease-in-out;

  @media ${device.laptop} {
    justify-content: space-between;
  }
`;

const Wrap = styled(Headroom)`
  .headroom {
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.11);

    &--unpinned {
      box-shadow: none;
    }

    &--unfixed {
    }

    &--unpinned,
    &--pinned {
      @media ${device.laptop} {
        .logo {
          /* width: ${FixedLogoWidth}px; */
        }

        .nav {
          /* margin: 0 0 0 auto; */
        }
      }
    }
  }
`;

const HeaderContainer = styled(Container)`
  height: 100%;
`;

const Hamburg = styled.button`
  background: none;
  border: none;
  display: inline-block;
  margin: 0 auto;

  @media ${device.laptop} {
    display: none;
  }
`;

const Nav = styled.nav`
  background-color: #fff;
  height: calc(100vh - 50px);
  left: 0;
  max-height: ${props => (props.open ? 'calc(100vh - 50px)' : '0')};
  overflow: hidden;
  position: absolute;
  top: 55px;
  transition: ${props => (props.open ? 'all 0.25s ease-in-out' : 'max-height 0.25s ease-in-out, visibility 0s 0.25s')};
  visitibility: ${props => (props.open ? 'visible' : 'hidden')};
  width: 100%;
  /* transition: width 0.2s ease-in-out; */
  /* width: 100%; */

  @media ${device.laptop} {
    background-color: transparent;
    height: 100%;
    overflow: visible;
    position: static;
    margin: 0 auto;
    max-height: none;
    top: auto;
    visibility: visible;
    width: auto;
  }
`;

const NavList = styled.ul`
  ${listUnstyled()}
  height: 100%;

  > li {
    position: relative;

    &:not(:last-child) {
      a {
        border-bottom: 1px solid #eee;

        @media ${device.laptop} {
          border-bottom: none;
        }
      }
    }
    @media ${device.laptop} {
      display: inline-block;
      height: 100%;
    }
    a {
      display: block;
      font-size: 14px;
      padding: 10px 3%;

      @media ${device.laptop} {
        align-items: center;
        display: flex;
        font-size: 16px;
        height: 100%;
        padding: 0 20px;
        text-align: center;
      }

      span {
        display: none;

        @media ${device.laptop} {
          display: block;
        }
      }
    }
  }
`;

const DropdownList = styled.ul`
  ${listUnstyled()}

  ${HeaderLink} {
    &:not(:last-child) {
      a {
        border-bottom: 1px solid #eee;
      }
    }

    a {
      height: auto;
      font-size: 13px;
      padding: 7.5px 8%;

      @media ${device.laptop} {
        margin: 0px 20px;
      }
    }
  }
`;

const menuId = '44oy07Od6wXTlhmHuB3pkT';

const Header = ({height}) => {
  const [menu, actions] = useMenuById(menuId);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!menu) {
      actions.menuGet(menuId);
    }
  }, []);

  return (
    <>
      <Wrap>
        {/* <Headroom> */}
        <HeaderContainer>
          <HeaderInner height={height}>
            <Hamburg onClick={() => setOpen(!open)}>
              <FontAwesomeIcon icon={faListUl} size="2x" />
            </Hamburg>
            <Nav open={open}>
              <NavList>
                {menu &&
                  menu.fields.links &&
                  menu.fields.links
                    .filter(l => l.fields)
                    .map((link, index) => {
                      const isDropdown = link.sys.contentType.sys.id === 'menuGroup';
                      const hasDot = index + 1 !== menu.fields.links.length;
                      return (
                        <HeaderLink
                          hasDot={hasDot}
                          key={link.sys.id}
                          tag="li"
                          link={isDropdown ? null : link}
                          text={isDropdown ? link.fields.title : null}
                        >
                          {link.sys.contentType.sys.id === 'menuGroup' ? (
                            <Dropdown>
                              <DropdownInner>
                                <DropdownList>
                                  {link.fields.references.map((dropdownLink, idx) => (
                                    <HeaderLink key={`${dropdownLink.sys.id}-${idx}`} tag="li" link={dropdownLink} />
                                  ))}
                                </DropdownList>
                              </DropdownInner>
                            </Dropdown>
                          ) : null}
                        </HeaderLink>
                      );
                    })}
              </NavList>
            </Nav>
          </HeaderInner>
        </HeaderContainer>
        {/* </Headroom> */}
      </Wrap>
    </>
  );

  // return (
  //   <>
  //     <Wrap>
  //       {/* <Headroom> */}
  //       <HeaderContainer>
  //         <HeaderInner>
  //           <Hamburg onClick={this.handleToggle}>
  //             <FontAwesomeIcon icon={faListUl} size="2x" />
  //           </Hamburg>
  //           <Nav open={this.state.open}>
  //             <NavList>
  //               <HeaderLink hasDot tag="li" url="/" text="Home">
  //                 <Dropdown>
  //                   <DropdownInner>
  //                     <DropdownList>
  //                       <HeaderLink tag="li" url="/" text="Sublink 1"></HeaderLink>
  //                       <HeaderLink tag="li" url="/" text="Sublink 2"></HeaderLink>
  //                       <HeaderLink tag="li" url="/" text="Sublink 3"></HeaderLink>
  //                       <HeaderLink tag="li" url="/" text="Sublink 4"></HeaderLink>
  //                     </DropdownList>
  //                   </DropdownInner>
  //                 </Dropdown>
  //               </HeaderLink>
  //               <HeaderLink hasDot tag="li" url="/about" text="About"></HeaderLink>
  //               <HeaderLink hasDot tag="li" url="/about" text="Fashion"></HeaderLink>
  //               <HeaderLink hasDot tag="li" url="/about" text="Photoshootings"></HeaderLink>
  //               <HeaderLink hasDot tag="li" url="/about" text="Photography"></HeaderLink>
  //               <HeaderLink tag="li" url="/about" text="Editor's Choice"></HeaderLink>
  //             </NavList>
  //           </Nav>
  //         </HeaderInner>
  //       </HeaderContainer>
  //       {/* </Headroom> */}
  //     </Wrap>
  //     <Logo>
  //       <Link to="/">
  //         <img src={LogoImg} alt="Fashion On The 4th Floor Logo" />
  //       </Link>
  //     </Logo>
  //   </>
  // );
};

Header.propTypes = {
  height: PropTypes.number
};

Header.deafultProps = {
  height: 60
};

export default Header;

// export default class Header extends PureComponent {
//   constructor(props) {
//     super(props);

//     this.state = {
//       open: false
//     };

//     this.handleToggle = this.handleToggle.bind(this);
//   }

//   handleToggle() {
//     this.setState(prevState => ({
//       open: !prevState.open
//     }));
//   }

//   findMenu(state) {
//     return state.menus.find(m => m.sys.id.toString() === menuId.toString());
//   }

//   render()
// }
