import {css, createGlobalStyle} from 'styled-components';
import {device} from './responsive';

export const TypographyStyles = createGlobalStyle`
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  line-height: 110%;
  font-family: Quicksand, 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
  font-weight: normal;
}

h1 {
  font-size: 48px;

  @media ${device.laptop} {
    font-size: 48px;
  }
}
h2 {
  font-size: 36px;

  @media ${device.laptop} {
    font-size: 40px;
  }
}
h3 {
  font-size: 28px;

  @media ${device.laptop} {
    font-size: 36px;
  }
}
h4 {
  font-size: 24px;

  @media ${device.laptop} {
    font-size: 30px;
  }
}
h5 {
  font-size: 22px;

  @media ${device.laptop} {
    font-size: 24px;
  }
}
h6 {
  font-size: 18px;

  @media ${device.laptop} {
    font-size: 20px;
  }
}

p {
  margin: 1em 0 1.5em 0;
}

a,
select,
input,
textarea,
button {
  color: #343434;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a:hover,
span {
  color: #b565a7;
}

select,
input,
textarea,
button {
  font-family: Muli, 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
}
`;
