import moment from 'moment';

export function noop() { }

export function isFunction(func) {
  return func && typeof func === 'function';
}

export function click(func, val) {
  return e => {
    if (isFunction(func)) {
      return func(val || typeof val !== 'undefined' ? val : e);
    }

    return null;
  };
}

export function clickPrevent(func, val) {
  return e => {
    e.preventDefault();
    if (isFunction(func)) {
      return func(val || typeof val !== 'undefined' ? val : e);
    }

    return null;
  };
}

export const prettyDate = date => {
  if (!date) {
    return;
  }

  return moment(date)
    .format('MMMM D, YYYY');
};

export const addThisRefresh = () => {
  let itv = null;

  if (!window) {
    return;
  }

  itv = setInterval(() => {
    if (!window.addthis.layers.refresh) {
      return;
    }

    window.addthis.layers.refresh();
    clearInterval(itv);
  }, 200);
};

export function canUseDOM() {
  return Boolean(typeof window !== 'undefined' && window.document && window.document.createElement);
}
