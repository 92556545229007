import {createStore, createSubscriber, createHook, defaults} from 'react-sweet-state';
import contentful from '../../services/contentful';

defaults.devtools = true;

const getMenu = id => {
  return contentful
    .getEntry(id, {
      content_type: 'menu',
      limit: 1,
      include: 10
    })
    .then(posts => {
      return posts;
    })
    .catch(error => {
      throw new Error(error);
    });
};

const getPost = slug => {
  return contentful
    .getEntry(null, {
      content_type: 'blogPost',
      limit: 1,
      include: 10,
      'fields.slug': slug
    })
    .then(posts => {
      return posts;
    })
    .catch(error => {
      throw new Error(error);
    });
};

const getPosts = (query, page, perpage) => {
  return contentful
    .getEntries({
      content_type: 'blogPost',
      limit: perpage,
      skip: page ? page * Number(perpage) : 0,
      include: 10,
      ...query
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      throw new Error(error);
    });
};

const getPage = slug => {
  return contentful
    .getEntry(null, {
      content_type: 'page',
      limit: 1,
      include: 10,
      'fields.slug': slug
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      throw new Error(error);
    });
};

const getCategory = slug => {
  return contentful
    .getEntry(null, {
      content_type: 'category',
      limit: 1,
      include: 10,
      'fields.slug': slug
    })
    .then(posts => {
      return posts;
    })
    .catch(error => {
      throw new Error(error);
    });
};

const getAuthor = id => {
  return contentful
    .getEntry(id, {
      content_type: 'person',
      limit: 1,
      include: 10
    })
    .then(posts => {
      return posts;
    })
    .catch(error => {
      throw new Error(error);
    });
};

const setLoading = ({getState, setState, key, loading}) => {
  const state = getState();
  return setState({
    ...state,
    loading: {
      ...state.loading,
      [key]: loading
    }
  });
};

const setError = ({getState, setState, key, error}) => {
  const state = getState();
  return setState({
    ...state,
    error: {
      ...state.error,
      [key]: error
    }
  });
};

const setMessage = ({getState, setState, key, message}) => {
  const state = getState();
  return setState({
    ...state,
    message: {
      ...state.message,
      [key]: message
    }
  });
};

const setMeta = ({getState, setState, key, meta}) => {
  const state = getState();
  return setState({
    ...state,
    meta: {
      ...state.meta,
      [key]: meta
    }
  });
};

const setNotification = ({getState, setState, key, notification}) => {
  const state = getState();
  return setState({
    ...state,
    notifications: {
      ...state.meta,
      [key]: notification
    }
  });
};

const addToList = ({setState, getState, key, items = []}) => {
  if (!Array.isArray(items)) {
    items = [items];
  }

  const state = getState();
  // Filter out the items that are already in the array
  const newItems = items.filter(i => !state[key].find(si => si.sys.id.toString() === i.sys.id.toString()));
  const newList = state[key].concat(newItems);

  const newState = {
    ...state,
    [key]: newList
  };

  return setState(newState);
};

const setNewList = ({setState, getState, key, items = []}) => {
  if (!Array.isArray(items)) {
    items = [items];
  }

  const state = getState();

  const newState = {
    ...state,
    [key]: items
  };

  return setState(newState);
};

const setLoadingForSlug = (key, loading) => ({setState, getState}) => {
  return setLoading({setState, getState, key, loading});
  return setTimeout(() => {
    setLoading({setState, getState, key, loading});
  }, key === 'mainContent' && loading === false ? 300 : 0);
};

const setNotificationForSlug = (key, notification) => ({setState, getState}) => {
  setNotification({setState, getState, key, notification});
};

const menuGet = id => ({setState, getState}) => {
  setLoading({setState, getState, key: id, loading: true});
  getMenu(id)
    .then(items => addToList({setState, getState, id, items, key: 'menus'}))
    .catch(error => setError({setState, getState, key: id, error}))
    .finally(() => setLoading({setState, getState, loading: false, key: id}));
};

const postGet = slug => ({setState, getState}) => {
  setLoading({setState, getState, key: slug, loading: true});
  getPost(slug)
    .then(items => addToList({setState, getState, id: slug, items, key: 'posts'}))
    .catch(error => setError({setState, getState, key: slug, error}))
    .finally(() => setLoading({setState, getState, loading: false, key: slug}));
};

const authorGet = id => ({setState, getState}) => {
  setLoading({setState, getState, key: id, loading: true});
  getAuthor(id)
    .then(items => addToList({setState, getState, id, items, key: 'authors'}))
    .catch(error => setError({setState, getState, key: id, error}))
    .finally(() => setLoading({setState, getState, loading: false, key: id}));
};

const pageGet = slug => ({setState, getState}) => {
  setLoading({setState, getState, key: slug, loading: true});
  getPage(slug)
    .then(items => addToList({setState, getState, id: slug, items, key: 'pages'}))
    .catch(error => setError({setState, getState, key: slug, error}))
    .finally(() => setLoading({setState, getState, loading: false, key: slug}));
};

const postsGet = (id, query, page, perpage) => ({setState, getState}) => {
  setLoading({setState, getState, key: id, loading: true});
  setNewList({setState, getState, items: [], key: id});
  setMessage({setState, getState, key: id, message: null});

  getPosts(query, page, perpage)
    .then(data => {
      if (!data.items || data.items.length === 0) {
        setMessage({setState, getState, key: id, message: 'No posts found!'});
      }

      setNewList({setState, getState, items: data.items.map(i => i.sys.id), key: id});
      addToList({setState, getState, id: id, items: data.items, key: 'posts'});
      setMeta({setState, getState, key: id, meta: {total: data.total}});
    })
    .catch(error => setError({setState, getState, key: id, error}))
    .finally(() => setLoading({setState, getState, loading: false, key: id}));
};

const categoryGet = slug => ({setState, getState}) => {
  setLoading({setState, getState, key: slug, loading: true});
  getCategory(slug)
    .then(items => addToList({setState, getState, id: slug, items, key: 'categories'}))
    .catch(error => setError({setState, getState, key: slug, error}))
    .finally(() => setLoading({setState, getState, loading: false, key: slug}));
};

const Store = createStore({
  initialState: {
    menus: [],
    posts: [],
    pages: [],
    authors: [],
    categories: [],
    archive: [],
    loading: {
      mainContent: true
    },
    error: {},
    meta: {},
    message: {},
    notifications: {}
  },
  actions: {
    menuGet,
    postGet,
    postsGet,
    pageGet,
    authorGet,
    categoryGet,
    setLoadingForSlug,
    setNotificationForSlug
  },
  name: 'AppStore'
});

const getMenuById = (state, id) => state.menus.find(m => m.sys.id.toString() === id.toString());
export const useMenuById = createHook(Store, {
  selector: getMenuById
});

const getMetaById = (state, id) => state.meta[id];
export const useMetaById = createHook(Store, {
  selector: getMetaById
});

const getMessageById = (state, id) => state.message[id];
export const useMessageById = createHook(Store, {
  selector: getMessageById
});

const selectPostBySlug = (state, slug) => state.posts.find(m => m.fields.slug === slug);
export const usePostBySlug = createHook(Store, {
  selector: selectPostBySlug
});
const selectPageBySlug = (state, slug) => state.pages.find(m => m.fields.slug === slug);
export const usePageBySlug = createHook(Store, {
  selector: selectPageBySlug
});

const selectPosts = state => state.posts;
export const usePosts = createHook(Store, {
  selector: selectPosts
});

const selectArchive = (state, id) => state[id] && state[id].map(a => state.posts.find(p => a.toString() === p.sys.id.toString())).filter(p => p);
export const useArchive = createHook(Store, {
  selector: selectArchive
});

const selectCategoryBySlug = (state, slug) => state.categories.find(m => m.fields.slug === slug);
export const useCategoryBySlug = createHook(Store, {
  selector: selectCategoryBySlug
});

const getAuthorById = (state, id) => state.authors.find(a => a.sys.id.toString() === id);
export const useAuthorById = createHook(Store, {
  selector: getAuthorById
});

const getErrorById = (state, id) => state.error[id];
export const useErrorById = createHook(Store, {
  selector: getErrorById
});

const getLoadingById = (state, id) => state.loading[id];
export const useLoadingById = createHook(Store, {
  selector: getLoadingById
});

const getNotificationBySlug = (state, slug) => state.notifications[slug];
export const useNotificationBySlug = createHook(Store, {
  selector: getNotificationBySlug
});

export const StoreSubscriber = createSubscriber(Store);

export const useStore = createHook(Store);
