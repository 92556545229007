import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Btn = styled.button`
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 20px;
  background: #aaa;
  color: #fff;
  border-radius: 3px;
  transition: all 0.25s ease-in-out;

  &:hover {
    background: #B565A7;
  }

  &:disabled {
    user-select: none;
    pointer-events: none;
    background: #aaa !important;
  }

`;

const Button = ({type, className, children, onClick, disabled}) => (
  <Btn disabled={disabled} className={className} type={type} onClick={onClick}>
    {children}
  </Btn>
);

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  type: 'button'
};

export default Button;
