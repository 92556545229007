import React from 'react';
import PropTypes from 'prop-types';

const Form = ({onSubmit, className, children}) => (
  <form className={className} onSubmit={onSubmit}>
    {children}
  </form>
);

Form.propTypes = {
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any
};

export default Form;
