import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import Container from './Container';
import {device} from '../../styles/responsive';
import FooterHeader from '../pieces/FooterHeader';
import Image from '../pieces/Image';
import SocialIcons from '../pieces/SocialIcons';
import {listUnstyled} from '../../styles/mixins';
import listIcon from '../../img/list-icon.png';
import PopularPosts from '../containers/PopularPosts';
import {getPostLink} from '../../utils/routeHelpers';
import {prettyDate} from '../../utils/componentHelpers';
import ReferenceLink from '../pieces/ReferenceLink';
import {useMenuById, useAuthorById} from '../data/Store';
import Wysiwyg from '../pieces/Wysiwyg';

const Foot = styled.footer`
  background-color: #fff;
  padding: 40px 0 0 0 ;

  @media ${device.laptop} {
    width: 100%;
  }
`;

const FooterCol = styled.div`
  color: #999;
  /* max-width: 400px; */
  margin-bottom: 30px;

  @media ${device.laptop} {
    margin-bottom: 60px;
  }
`;

const FooterImg = styled(Image)``;

const AboutWidget = styled(FooterCol)`
  ${FooterImg} {
    margin-bottom: 15px;
  }

  p {
    margin: 0 0 25px;
  }
`;

const Posts = styled.div`
`;

const Post = styled(Link)`
  display: flex;
  margin: 0px 0 20px 0;
  padding-bottom: 10px;

  &:not(:last-child) {
    border-bottom: 1px dotted #ddd;
  }
`;

const PostImageWrap = styled.div`
  flex: 0 0 110px;
`;

const PostImage = styled(Image)`
  object-fit: cover;
`;

const PostContent = styled.div`
  padding-left: 10px;
`;

const PostTitle = styled.h4`
  line-height: 16px;
  margin: 5px 0 0;
  text-align: left;
  font-size: 15px;
`;

const PostDate = styled.span`
  font-size: 12px;
  color: #aaa;
  font-style: italic;
`;

const LinkList = styled.ul`
  ${listUnstyled()};

  li {
    padding-bottom: 5px;
    margin-bottom: 5px;
    list-style-type: none;
    background: url(${listIcon}) 0 13px no-repeat;
    padding-left: 15px;

    &:not(:last-child) {
      border-bottom: 1px dotted #ddd;
    }
  }
`;

const Copyright = styled.div`
  background: #f0f0f0;
  color: #bbb;
  padding: 25px 0;
`;

const CopyText = styled.div`
  margin: 0 0 10px;

  @media ${device.laptop} {
    margin: 0;
  }
`;

const menuId = '2uZJtAHSlgM8hZFv1FuyBU';

const authorId = '7vBSJQUCYPzHvPs3hmWilt';

const Footer = () => {
  const [menu, actions] = useMenuById(menuId);
  const [author] = useAuthorById(authorId);

  useEffect(
    () => {
      if (!menu) {
        actions.menuGet(menuId);
      }

      if (!author) {
        actions.authorGet(authorId);
      }
    },
    []
  );

  return (
    <Foot>
      <Container>
        <Row>
          <Col md={4}>
            <FooterHeader text="About Me" />
            <AboutWidget>
              <FooterImg src={author && author.fields.footerImage ? author.fields.footerImage.fields.file.url : ''} />
              <Wysiwyg content={author ? author.fields.footerBlurb : '' }/>
              <SocialIcons />
            </AboutWidget>
          </Col>
          <Col md={4}>
            <FooterHeader text="Popular Posts" />
            <PopularPosts>
              {posts => (
                <Posts>
                  {posts && posts.map(post => (
                    <Post key={post.sys.id} to={getPostLink(post)}>
                      <PostImageWrap>
                        <PostImage src={post.fields.heroImage ? post.fields.heroImage.fields.file.url : null} />
                      </PostImageWrap>
                      <PostContent>
                        <PostTitle>{post.fields.title}</PostTitle>
                        <PostDate>{prettyDate(post.fields.publishDate)}</PostDate>
                      </PostContent>
                    </Post>
                  ))}
                </Posts>
              )}
            </PopularPosts>

          </Col>
          <Col md={4}>
            <FooterHeader text="Categories" />
            <LinkList>
              {/* <Menu id=""> */}
              {menu && menu.fields.links && menu.fields.links.map(link => (
                <li key={link.sys.id}>
                  <ReferenceLink reference={link} />
                </li>
              ))}
              {/* </Menu> */}
            </LinkList>
          </Col>
        </Row>

      </Container>
      <Copyright>
        <Container>
          <Row>
            <Col md={6}>
              <CopyText>© 2019 copyright Fashion On The 4th Floor // All rights reserved</CopyText>
            </Col>
            {/* <Col md={6}>
              <CopyText>Zarja was made with love by Premiumcoding</CopyText>
            </Col> */}
          </Row>
        </Container>
      </Copyright>
    </Foot>
  );
};

Footer.propTypes = {};

export default Footer;
