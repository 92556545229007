import { css, createGlobalStyle } from 'styled-components';
import { device } from './responsive';

export const globalStyles = css`
  /* Setup base 10 stuff */
  :root {
    font-size: 62.5%;
  }

  html,
  body {
    font-family: Muli, 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    min-height: 100vh;
  }

  body {
    background-color: #f4f4f4;
    color: #444;
    line-height: 1.85em;
  }

  #root {
    min-height: 100vh;
    position: relative;
  }

  ::selection {
    background: #000;
    color: #fff;
    text-shadow: none;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  a {
    transition: all 0.25s ease-in-out;

    &:hover {
      color: #B565A7;
      text-decoration: none !important;
    }
  }
`;

export const GlobalStyles = createGlobalStyle(globalStyles);

export default globalStyles;
