import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const ReferenceLink = ({reference, otherProps, children, className}) => {
  const props = {
    key: reference.sys.id,
    className,
    ...otherProps
  };

  if (reference.sys.contentType.sys.id === 'category') {
    return (
      <Link {...props} to={`/category/${reference.fields.slug}`}>
        {reference.fields.name}
        {children}
      </Link>
    );
  }

  if (reference.sys.contentType.sys.id === 'page') {
    return (
      <Link {...props} to={`/${reference.fields.slug}`}>
        {reference.fields.title}
        {children}
      </Link>
    );
  }

  if (reference.sys.contentType.sys.id === 'link') {
    return (
      <a {...props} href={reference.fields.url}>
        {reference.fields.title}
        {children}
      </a>
    );
  }

  return null;
};

ReferenceLink.propTypes = {
  reference: PropTypes.object,
  otherProps: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string
};

ReferenceLink.defaultProps = {
  otherProps: {}
};

export default ReferenceLink;
