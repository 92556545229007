import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import variables from '../../styles/variables';
import PostContent from './PostContent';
import {prettyDate} from '../../utils/componentHelpers';
import {device} from '../../styles/responsive';
import Sharing from './Sharing';
import {getPostLink} from '../../utils/routeHelpers';

const PostPreviewWrap = styled.div`
  background-color: #fff;
  padding-bottom: 20px;
  position: relative;
`;

const Footer = styled.div`
  height: 100px;
  margin-top: 20px;
  position: relative;

  @media ${device.laptop} {
    height: 70px;
  }
`;

const Tag = styled.div`
  background: #B565A7;
  border-radius: 2px;
  bottom: 30px;
  color: #fff;
  font-family: ${variables.fontItalic};
  font-size: 20px;
  margin-top: -30px;
  position: absolute;

  @media ${device.laptop} {
    bottom: 30px;
  }

  &::after,
  &::before {
    content: '';
    border-bottom: 17px solid transparent;
    position: absolute;
    margin-top: 35px;
  }
`;

const Author = styled(Tag)`
  margin-right: -20px;
  padding: 6px 32px 6px 12px;
  right: 0;

  &::after {
    border-left: 19px solid #944787 !important;
    border-right: 0px solid #944787;
    margin-left: 13px;
  }
`;

const Time = styled(Tag)`
  left: 0;
  margin-left: -20px;
  padding: 6px 12px 6px 32px;

  &::before {
    border-left: 0px solid #944787;
    border-right: 19px solid #944787 !important;
    margin-left: -31px;
  }
`;

const A = styled(Link)`
  color: #343434;
  letter-spacing: 1px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;

  @media ${device.laptop} {
    left: 0;
    position: absolute;
  }

  &:hover {
    color: #B565A7;
  }
`;

const PostPreview = ({post, className}) => (
  <PostPreviewWrap className={className}>
    <PostContent
      title={post.fields.title}
      heroImage={post.fields.heroImage}
      body={post.fields.excerpt}
      category={post.fields.category}
      link={getPostLink(post)}
    />
    <Footer>
      <Time>{prettyDate(post.fields.publishDate)}</Time>
      <A to={getPostLink(post)}>Continue Reading</A>
      <Author>{post.fields.author.fields.name}</Author>
    </Footer>
    <Sharing
      title={post.fields.title}
      description={post.fields.description}
      media={post.fields.heroImage && post.fields.heroImage.fields ? post.fields.heroImage.fields.file.url : null}
      url={getPostLink(post, true)}
    />
  </PostPreviewWrap>
);

PostPreview.propTypes = {
  post: PropTypes.object,
  className: PropTypes.string
};

export default PostPreview;
