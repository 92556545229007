import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {listUnstyled} from '../../styles/mixins';
import Image from '../pieces/Image';
import {device} from '../../styles/responsive';
import {Container} from 'reactstrap';

const ig = require('instagram-scraping');

const Wrap = styled.div`
  background-color: #fff;
  padding: 45px 0 30px;
`;

const Title = styled.h5`
  font-size: 26px;
  margin: 0 0 0.7em;
  text-align: center;
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 36px;
  }

`;

const Grid = styled.ul`
  ${listUnstyled}
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media ${device.tablet} {
    flex-wrap: nowrap;
  }    

`;

const GridItem = styled.li`
  flex: 0 0 100%;
  width: 100%;

  @media ${device.mobileS} {
    flex: 0 0 50%;
    width: 50%;
  }

  @media ${device.mobileL} {
    flex: 0 0 ${100 / 3}%;
    width: 0 0 ${100 / 3}%;
  }

  @media ${device.tablet} {
    flex: 0 0 ${props => 100 / props.totalItems}%;
    width: ${props => 100 / props.totalItems}%;
  }

  a {
    display: block;
    height: 0;
    opacity: 1;
    padding-bottom: 100%;
    position: relative;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.85;
    }

    img {
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
    }
  }
`;

const InstagramFeed = () => {
  const [medias, setMedias] = useState([]);

  useEffect(() => {
    ig.scrapeUserPage('fashiononthe4thfloor').then(res => setMedias(res.medias ? res.medias.slice(0, 6) : []));
  }, []);

  return (
    <Wrap>
      <Container>
        <Title>Our Instagram Feed</Title>
      </Container>
      <Grid>
        {medias.map(m => (
          <GridItem key={m.media_id} totalItems={medias.length}>
            <a href={`//www.instagram.com/p/${m.shortcode}`} target="_blank" rel="noopener noreferrer">
              <Image src={m.thumbnail} alt={m.text} />
            </a>
          </GridItem>
        ))}
      </Grid>
    </Wrap>
  );
};

export default InstagramFeed;
