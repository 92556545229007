import React from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import axios from 'axios';
import {Col, Row} from 'reactstrap';
import styled from 'styled-components';

import Page from './Page';
import Form from '../form/Form';
import Input from '../form/Input';
import Button from '../pieces/Button';
import {useLoadingById, useNotificationBySlug} from '../data/Store';

const slug = 'about';

const Feedback = styled.span`
  color: #B565A7;
  margin-left: 10px;
`;

const About = ({match}) => {
  const [loading, actions] = useLoadingById(slug);
  const [notification] = useNotificationBySlug(slug);
  const {register, handleSubmit, reset} = useForm();

  const onSubmit = async formData => {
    actions.setLoadingForSlug(slug, true);
    actions.setNotificationForSlug(slug, '');
    await axios.get('https://hooks.zapier.com/hooks/catch/1517046/o6whzti/', {params: formData});
    await axios.get('https://hooks.zapier.com/hooks/catch/1517046/o6cx6eq/', {params: formData});
    actions.setLoadingForSlug(slug, false);
    actions.setNotificationForSlug(slug, 'Your message has been sent!');
    reset();
  };

  return (
    <Page slug={slug} match={match}>
      <Form onSubmit={handleSubmit(onSubmit)}>

        <Input required id="fname" name="fname" value="fname" label="First Name (required)" register={register}/>

        <Input required id="lname" name="lname" value="lname" label="Last Name (required)" register={register}/>

        <Input required id="email" name="email" value="email" type="email" label="Your Email (required)" register={register}/>

        <Input id="subject" name="subject" value="subject" label="Subject" register={register}/>

        <Input tag="textarea" id="message" name="message" value="message" label="Your Message" cols={40} rows={10} register={register}/>

        <Row>
          <Col>
            <Button disabled={loading} type="submit">
              Send
            </Button>
            <Feedback>{notification}</Feedback>
          </Col>
        </Row>
      </Form>
    </Page>
  );
};

About.propTypes = {
  match: PropTypes.object
};

export default About;
