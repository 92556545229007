import React from 'react';
import {HeadProvider, Title, Link, Meta} from 'react-head';
import PropTypes from 'prop-types';

import Icon from '../../img/fashion-4th-floor-re-do.jpg';
import IconSmall from '../../img/fashion-4th-floor-re-do-150x150.jpg';

const defaults = {
  title: 'Fashion on the 4th Floor – Style Transcends All Decades',
  siteTitle: 'Fashion on the 4th Floor',
  titleSep: ' - ',
  description: 'Style Transcends All Decades'
};

const Head = ({title, description}) => {
  return (
    <HeadProvider>
      <Title>{title ? `${title} - ${defaults.siteTitle}` : defaults.title}</Title>
      <Meta name="description" content={description || defaults.description}/>

      <Link rel="icon" href={IconSmall} sizes="192x192"/>
      <Link rel="icon" href={IconSmall} sizes="32x32"/>
      <Link rel="icon" href={Icon}/>
      <Link rel="apple-touch-icon-precomposed" href={Icon}/>
      <Meta name="msapplication-TileImage" content={Icon} />
    </HeadProvider>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

Head.defaultProps = {
  title: defaults.title,
  description: defaults.description
};

export default Head;
