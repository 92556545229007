import {useEffect} from 'react';
import PropTypes from 'prop-types';

import {useArchive} from '../data/Store';

const PopularPosts = ({children}) => {
  const [posts, actions] = useArchive('popularPosts');

  useEffect(
    () => {
      actions.postsGet('popularPosts', {
        'fields.isPopular': true
      }, 0, 3);
    },
    []
  );

  return children(posts);
};

PopularPosts.propTypes = {
  children: PropTypes.func
};

export default PopularPosts;
