import React, {Component, createRef} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const WrapCss = css`
  height: 0;
  padding-bottom: ${props => props.ratio * 100}%;
  position: relative;
`;

const Wrap = styled.div`
  ${WrapCss};
`;

const WrapSpan = styled.span`
  ${WrapCss}
  display: block;
`;

const Img = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
`;

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      width: props.width,
      height: props.height
    };

    this.handleLoad = this.handleLoad.bind(this);
  }

  static getDerrivedStateFromProps(props, state) {
    return {
      height: props.height || state.height,
      width: props.width || state.width
    };
  }

  handleLoad({target: el}) {
    this.setState({
      loaded: true,
      height: this.props.height || el.naturalHeight,
      width: this.props.width || el.naturalWidth
    });
  }

  render() {
    const {src, alt, defaultRatio, className, wrapTag, style} = this.props;
    const {height, width} = this.state;

    const WrapEl = wrapTag === 'div' ? Wrap : WrapSpan;

    return (
      <WrapEl className={className} ratio={height / width || defaultRatio}>
        <picture>
          <Img src={src} alt={alt} onLoad={this.handleLoad} style={style} />
        </picture>
      </WrapEl>
    );
  }
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  defaultRatio: PropTypes.number,
  className: PropTypes.string,
  wrapTag: PropTypes.string,
  style: PropTypes.object
};

Image.defaultProps = {
  defaultRatio: 0.5625,
  wrapTag: 'div',
  style: {}
};

export default Image;
