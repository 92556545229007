import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputWrap = styled.div`

  input,
  textarea {
    background: #fefefe;
    border: 1px solid #eeeeee;
    display: block;
    margin-bottom: 20px;
    padding: 5px;

    &:focus {
      border: 1px solid #dddddd;
      outline: none;
    }
  }

  input {
    padding-left: 10px !important;
    height: 34px;
    width: 50%;
  }

  textarea {
    width: 98%;
  }
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

const Input = ({tag: Tag, className, required, id, name, label, type, value, onChange, cols, rows, register}) => (
  <InputWrap className={className}>
    <Label htmlFor={id}>{label}</Label>
    <Tag ref={register} required={required} id={id} name={name} type={type} cols={cols} rows={rows} />
  </InputWrap>
);

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tag: PropTypes.string,
  required: PropTypes.bool,
  cols: PropTypes.number,
  rows: PropTypes.number,
  register: PropTypes.any
};

Input.defaultProps = {
  type: 'text',
  tag: 'input'
};

export default Input;
