import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SharingWrap = styled.div`
  .addthis_inline_share_toolbox {
    display: flex;
    justify-content: center;
  }

  .at-share-btn {
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    z-index: 9;
    background-color: #ccc !important;
    border-radius: 90% !important;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 6px 5px !important;

    .at4-visually-hidden {
      display: none;
    }

    svg {
      width: 28px !important;
      height: 28px !important;
    }

    &:hover {
      background-color: #B565A7 !important;
    }
  }
`;

const SharingInner = styled.div`
  border-top: 2px solid #B565A7;
  background-color: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
  padding: 20px;
  margin: -14px auto 0;
  width: 240px;
  position: absolute;
  left: 0;
  right: 0;
`;

class Sharing extends Component {
  render() {
    const {className, title, description, media, url} = this.props;
    const sharingProps = {};

    if (title) {
      sharingProps['data-title'] = title;
    }

    if (description) {
      sharingProps['data-description'] = description;
    }

    if (media) {
      sharingProps['data-media'] = media;
    }

    if (url) {
      sharingProps['data-url'] = url;
    }

    return (
      <SharingWrap className={className}>
        <SharingInner>
          <div className="addthis_inline_share_toolbox" {...sharingProps}></div>
        </SharingInner>
      </SharingWrap>
    );
  }
}

Sharing.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  media: PropTypes.string,
  url: PropTypes.string
};

export default Sharing;
