import React, {Component, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import styled from 'styled-components';

import contentful from '../../services/contentful';
import {device} from '../../styles/responsive';
import PostPreview from '../pieces/PostPreview';
import Container from '../layout/Container';
import {addThisRefresh, noop} from '../../utils/componentHelpers';
import Pagination from '../pieces/Pagination';
import NotFound from '../pages/NotFound';
import {usePosts, useMetaById, useMessageById, useArchive} from '../data/Store';

const ContentWrap = styled.div`
  margin-top: 80px;
`;

const Post = styled(PostPreview)`
  margin-bottom: 100px;
`;

const MessageWrap = styled.div`
  background-color: #fff;
  padding: 20px 15px;
  margin-bottom: 80px;
`;

const perpage = 5;

const Archive = ({page, slug, query}) => {
  const [posts, actions] = useArchive('archive');
  const [meta] = useMetaById('archive');
  const [message] = useMessageById('archive');

  useEffect(
    () => {
      actions.postsGet('archive', query, page ? page - 1 : 0, perpage);
    },
    [page, slug, query]
  );

  return (
    <>
      <ContentWrap>
        <Container>
          {message ? <MessageWrap><h2>{message}</h2></MessageWrap> : null}
          {posts && posts.map(post => (
            <Post key={post.sys.id} post={post} />
          ))}
          <Pagination
            onClick={noop}
            total={meta ? meta.total : 0}
            perPage={perpage}
            currentPage={page || 1}
            baseUrl={slug ? `/category/${slug}` : ''}
          />
        </Container>
      </ContentWrap>
    </>
  );
};

Archive.propTypes = {
  query: PropTypes.object,
  page: PropTypes.number.isRequired,
  slug: PropTypes.string
};

export default Archive;

// class Archive extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       posts: [],
//       error: null,
//       loading: false,
//       total: -1,
//       message: null
//     };
//   }

//   componentDidMount() {
//     this.getPosts();
//   }

//   componentDidUpdate(prevProps) {
//     if (this.props.slug !== prevProps.slug || this.props.page !== prevProps.page) {
//       this.getPosts();
//     }

//     addThisRefresh();
//   }

//   getPosts() {
//     this.setState({loading: true, message: null});
//     getPosts(this.props.query, this.props.page)
//       .then(data => this.setState(prevState => {
//         let message = prevState.message;
//         if (!data.items || data.items.length === 0) {
//           message = 'No posts found!';
//         }

//         return {...prevState, message, posts: data.items, total: data.total, error: null};
//       }))
//       .catch(error => this.setState({error, posts: []}))
//       .finally(() => this.setState({loading: false}));
//   }

//   render() {
//     return (
//       <>
//         <ContentWrap>
//           <Container>
//             {this.state.message ? <MessageWrap><h2>{this.state.message}</h2></MessageWrap> : null}
//             {this.state.posts.map(post => (
//               <Post key={post.sys.id} post={post} />
//             ))}
//             <Pagination
//               onClick={noop}
//               total={this.state.total}
//               perPage={perpage}
//               currentPage={this.props.page}
//               baseUrl={`/category/${this.props.slug}`}
//             />
//           </Container>
//         </ContentWrap>
//       </>
//     );
//   }
// }
