import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTwitter, faFacebookF, faDribbble, faPinterest, faInstagram} from '@fortawesome/free-brands-svg-icons';

const Icon = styled.a`
  color: #fff;
  display: inline-block;
  margin-right: 15px;
  border-radius: 90%;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;

  &:hover {
    color: #fff;
    opacity: 0.5;
  }
`;

const Wrap = styled.div`
  ${Icon} {
    background: ${props => props.color};
  }
`;

const SocialIcons = ({className, color, facebook, instagram}) => (
  <Wrap className={className} color={color}>
    {/* <Icon target="_blank" href="http://twitter.com/PremiumCoding" title="Twitter" rel="noopener" ariaTitle="Twitter">
      <FontAwesomeIcon icon={faTwitter} aria-hidden="true"></FontAwesomeIcon>
    </Icon> */}
    <Icon target="_blank" href={facebook} title="Facebook" ariaTitle="Facebook">
      <FontAwesomeIcon icon={faFacebookF} aria-hidden="true"></FontAwesomeIcon>
    </Icon>
    {/* <Icon target="_blank" href="https://dribbble.com/gljivec" title="Dribbble" ariaTitle="Dribble">
      <FontAwesomeIcon icon={faDribbble} aria-hidden="true"></FontAwesomeIcon>
    </Icon>
    <Icon target="_blank" href="http://www.pinterest.com/gljivec/" title="Pinterest" ariaTitle="Pinterest">
      <FontAwesomeIcon icon={faPinterest} aria-hidden="true"></FontAwesomeIcon>
    </Icon> */}
    <Icon target="_blank" href={instagram} title="Instagram" ariaTitle="Instagram">
      <FontAwesomeIcon icon={faInstagram} aria-hidden="true"></FontAwesomeIcon>
    </Icon>
  </Wrap >
);

SocialIcons.propTypes = {
  color: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string
};

SocialIcons.defaultProps = {
  color: '#B565A7'
};

export default SocialIcons;
