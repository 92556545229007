import React, {Component, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import styled from 'styled-components';

import contentful from '../../services/contentful';
import {device} from '../../styles/responsive';
import PostPreview from '../pieces/PostPreview';
import Container from '../layout/Container';
import {addThisRefresh, noop} from '../../utils/componentHelpers';
import Pagination from '../pieces/Pagination';
import Archive from '../containers/Archive';
import NotFound from './NotFound';
import {useCategoryBySlug, useErrorById} from '../data/Store';
import Head from '../layout/Head';
import Loading from '../pieces/Loading';

const categoryQuery = slug => {
  return {
    'fields.category.sys.contentType.sys.id': 'category',
    'fields.category.fields.slug[match]': slug
  };
};

const Category = ({match: {params: {slug, page}}}) => {
  const [category, actions] = useCategoryBySlug(slug);
  const [error] = useErrorById(slug);

  useEffect(
    () => {
      if (!category) {
        actions.categoryGet(slug);
        actions.setLoadingForSlug('mainContent', true);
      }
    },
    [slug]
  );

  if (error) {
    return <NotFound />;
  }

  if (!category) {
    return <Loading/>;
  }

  actions.setLoadingForSlug('mainContent', false);

  // return null;

  return (
    <>
      <Head title={category.fields.name} description={category.fields.description}/>
      <Archive key={slug} slug={slug} page={parseInt(page, 10) || 0} query={categoryQuery(slug)} />
    </>
  );
};

export default Category;
