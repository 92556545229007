import React from 'react'
import styled from 'styled-components';

const Wrap = styled.div``;

const Text = styled.h3`
  font-size: 15px;
  border: 1px solid #B565A7;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 0;
  margin-bottom: 20px;
  font-weight: bold;
`;

const FooterHeader = ({ text }) => (
  <Wrap>
    <Text>{text}</Text>
  </Wrap>
)

export default FooterHeader;
