import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {device} from '../../styles/responsive';
import wrapBg from '../../img/lower-ribbon.png';
import Image from '../pieces/Image';
import SocialIcons from '../pieces/SocialIcons';
import Container from '../layout/Container';
import variables from '../../styles/variables';
import Wysiwyg from '../pieces/Wysiwyg';

const AuthorBannerWrap = styled.div`
  background-color: #fff;
  margin-bottom: 60px;
  max-height: 421px;
  border-bottom: 1px solid #e5e5e5;
`;

const AuthorBannerContainer = styled(Container)`
  @media ${device.laptop} {
    background: url(${wrapBg}) bottom no-repeat;
    width: 100%;
    height: 479px;
    max-width: 1080px;
  }
`;

const AuthorBannerInner = styled.div`
  background: #fff;
  min-height: 300px;
  padding: 60px 0px;
  text-align: center;

  @media ${device.laptop} {
    display: grid;
    grid-column-gap: 35px;
    grid-template-columns: 300px auto;
    max-height: 421px;
    overflow: hidden;
    text-align: left;
  }
`;

const AuthorBannerImageWrap = styled.div``;

const AuthorBannerImage = styled(Image)`
  border: 8px solid #eee;
  border-radius: 50%;
  height: auto;
  margin: 0 auto;
  max-width: 285px;
  overflow: hidden;
  padding-bottom: 0;
  width: 100%;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  img {
    background: #fff;
    left: 0;
  }
`;

const AuthorBannerContent = styled.div`
  color: #333;

  p {
    font-family: ${variables.fontItalic};
    font-size: 22px;
    line-height: 40px;
    margin: 0 0 1rem;

    @media ${device.laptop} {
      font-size: 28px;
      line-height: 50px;
    }
  }
`;

const SocialIconWrap = styled.div`
  border-top: 1px solid #eee;
  margin: 15px 0 0;
  padding-top: 25px;

  a {
    margin-right: 10px;

    &:hover {
      background: #B565A7;
      opacity: 1;
    }
  }
`;

const AuthorBanner = ({image, blurb, facebook, instagram}) => (
  <AuthorBannerWrap>
    <AuthorBannerContainer>
      <AuthorBannerInner>
        <AuthorBannerImageWrap>
          <AuthorBannerImage src={image} />
        </AuthorBannerImageWrap>
        <AuthorBannerContent>
          <Wysiwyg content={blurb}/>
          <SocialIconWrap>
            <SocialIcons color="#000" facebook={facebook} instagram={instagram} />
          </SocialIconWrap>
        </AuthorBannerContent>
      </AuthorBannerInner>
    </AuthorBannerContainer>
  </AuthorBannerWrap>
);

AuthorBanner.propTypes = {
  image: PropTypes.string,
  blurb: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string
};

export default AuthorBanner;
