import { css } from 'styled-components';

export const listUnstyled = () => ({
  listStyle: 'none',
  margin: 0,
  padding: 0
});

export const clearfix = () => css`
  &::after {
    display: block;
    content: "";
    clear: both;
  }
`;
