import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';

import Image from '../pieces/Image';
import {prettyDate} from '../../utils/componentHelpers';
import {useArchive} from '../data/Store';

const Wrap = styled.div`
  background-color: #fff;
  margin: 0 0 50px;
  padding: 55px 0 25px;
`;

const Title = styled.h5`
  font-size: 36px;
  margin: 0 0 30px;
  text-align: center;
  text-transform: uppercase;
`;

const PostCard = styled.div`
  position: relative;
`;

const CardLink = styled(Link)`
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`;

const CardImage = styled.div``;

const CardTitle = styled.h4`
  font-family: Muli, 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
  font-size: 14px !important;
  line-height: 22px;
  margin: 8px 0 0;
  text-transform: uppercase;
`;

const CardDate = styled.p`
  color: #818181;
  font-size: 14px;
  font-style: italic;
  margin: -2px 0 0;
`;

const RelatedPosts = ({category, currentPost}) => {
  const [posts, actions] = useArchive('relatedPosts');

  useEffect(
    () => {
      actions.postsGet('relatedPosts', {
        'fields.category.sys.id': category,
        'sys.id[nin]': currentPost
      }, 0, 3);
    },
    [category, currentPost]
  );

  return (
    <Wrap>
      <Title>Related Posts</Title>
      <Row>
        {posts && posts.map((post, index) => (
          <Col key={index} md={4}>
            <PostCard>
              <CardLink to={`/${post.fields.slug}`} />
              <CardImage>
                <Image src={post.fields.heroImage ? post.fields.heroImage.fields.file.url : null} />
              </CardImage>
              <CardTitle>{post.fields.title}</CardTitle>
              <CardDate>{prettyDate(post.fields.publishDate)}</CardDate>
            </PostCard>
          </Col>
        ))}
      </Row>
    </Wrap>
  );
};

RelatedPosts.propTypes = {
  category: PropTypes.string,
  currentPost: PropTypes.string
};

export default RelatedPosts;

