import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {listUnstyled} from '../../styles/mixins';

const CatWrap = styled.div``;

const CatList = styled.ul`
  ${listUnstyled()}
  display: flex;
  justify-content: ${props => (props.centered ? 'center' : null)};
`;

const Category = styled.li``;

const CategoryLink = styled(Link)`
  background-color: #b565a7;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 8px 12px;
  text-transform: uppercase;

  &:hover {
    color: #fff;
  }
`;

const Categories = ({className, categories, centered}) => (
  <CatWrap className={className}>
    <CatList centered={centered}>
      {categories &&
        categories.map((category, index) => (
          <Category key={index}>
            <CategoryLink to={`/category/${category.slug}`}>{category.name}</CategoryLink>
          </Category>
        ))}
    </CatList>
  </CatWrap>
);

Categories.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array,
  centered: PropTypes.bool
};

Categories.defaultProps = {
  centered: true
};

export default Categories;
