import moment from 'moment';
import {canUseDOM} from './componentHelpers';

export const getPostLink = (post, includeDomain) => {
  const date = moment(post.fields.publishDate);
  const year = date.format('YYYY');
  const month = date.format('MM');
  const day = date.format('DD');

  return `${includeDomain ? canUseDOM() ? window.location.origin : '//www.fashiononthe4thfloor.com' : ''}/${year}/${month}/${day}/${post.fields.slug}`;
};
