import React, {Component, useEffect} from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import contentful from '../../services/contentful';
import Container from '../layout/Container';
import Image from '../pieces/Image';
import Categories from '../pieces/Categories';
import {device} from '../../styles/responsive';
import RelatedPosts from '../containers/RelatedPosts';
import PostContent from '../pieces/PostContent';
import Sharing from '../pieces/Sharing';
import {addThisRefresh} from '../../utils/componentHelpers';
import NotFound from './NotFound';
import {usePageBySlug, useErrorById} from '../data/Store';
import Wysiwyg from '../pieces/Wysiwyg';
import Head from '../layout/Head';
import Loading from '../pieces/Loading';

const renderers = {
  image: Image
};

const Wrap = styled.div`
  margin-bottom: 50px;
  margin-top: 60px;
`;

const PageContent = styled.div`
  background: #fff;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
  padding: 35px 25px;

  h1:first-child {
    margin-top: 0;
  }
`;

const Content = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
`;

const Header = styled.div`
  padding: 40px 15px 0;
  margin: 0 0 30px;
`;

const HeaderCategories = styled(Categories)``;

const Title = styled.h1`
  font-size: 28px;
  margin: 13px 0 17px;
  text-align: center;

  @media ${device.laptop} {
    font-size: 40px;
  }
`;

const Meta = styled.div`
  margin: -10px 0 20px 0;
  text-align: center;
`;

const Comments = styled.a`
  color: #aaa;
  text-transform: uppercase;
`;

const Hero = styled.div`
  margin: 0 0 15px;
`;

const Body = styled.div`
  background-color: #fff;
  padding: 0 15px 5px;

  ul,
  ol {
    margin-bottom: 1.25em;
    margin-top: 1.25em;
  }
`;

const AuthorInfo = styled.div`
  background-color: #fff;
  border-top: 5px solid #F4F4F4;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
  display: flex;
  margin: 1px 0 0;
  padding: 35px 0px;
`;

const AuthorImage = styled.div`
  flex: 0 0 133px;
  height: 82px;
  margin: 0 25px;
  width: 133px;

  ${Image} {
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
    }
  }
`;

const AuthorContent = styled.div`
  h4 {
    font-family: Muli, 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.85em;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    margin: 0;
  }
`;

// const Sharing = styled.div`
//   border-top: 2px solid #B565A7;

//   .addthis_inline_share_toolbox {
//     display: flex;
//     justify-content: center;
//   }

//   .at-share-btn {
//     text-align: center;
//     width: 40px;
//     height: 40px;
//     line-height: 40px;
//     z-index: 9;
//     background-color: #ccc !important;
//     border-radius: 90% !important;
//     color: #fff;
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     padding: 0;
//     margin: 0 6px 5px !important;

//     .at4-visually-hidden {
//       display: none;
//     }

//     svg {
//       width: 28px !important;
//       height: 28px !important;
//     }

//     &:hover {
//       background-color: #B565A7 !important;
//     }
//   }
// `;

// const SharingInner = styled.div`
//   background-color: #fff;
//   box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
//   padding: 20px;
//   margin: -14px auto 0;
//   width: 240px;
//   position: absolute;
//   left: 0;
//   right: 0;
// `;

const RelatedPostsWrap = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
  margin: 50px 0;
  padding: 0 15px;
`;

const Page = ({children, slug: propSlug, match: {params: {slug: routeSlug}}}) => {
  const slug = propSlug || routeSlug;

  const [page, actions] = usePageBySlug(slug);
  const [error] = useErrorById(slug);

  useEffect(
    () => {
      if (!page) {
        actions.pageGet(slug);
        actions.setLoadingForSlug('mainContent', true);
      }
    },
    []
  );

  if (error) {
    return <NotFound />;
  }

  if (!page) {
    return (
      <Loading/>
    );
  }

  actions.setLoadingForSlug('mainContent', false);

  return (
    <Wrap>
      <Head title={page.fields.title} description={page.fields.description}/>
      <Container>
        <PageContent>
          <Wysiwyg dropcap={page.fields.hasDropcap} content={page.fields.body}/>
          {children}
        </PageContent>
      </Container>
    </Wrap>
  );
};

Page.propTypes = {
  match: PropTypes.object,
  children: PropTypes.any,
  slug: PropTypes.string
};

Page.defaultProps = {
  children: null
};

export default Page;
