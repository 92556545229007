import React, {Suspense, lazy, Component} from 'react';
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';
import styled from 'styled-components';

import './App.css';
import {Normalize} from './styles/normalize';
import {GlobalStyles} from './styles/global';
import Header from './components/layout/Header';
import {TypographyStyles} from './styles/typography';
import InstagramFeed from './components/containers/InstagramFeed';
import Footer from './components/layout/Footer';
import {device} from './styles/responsive';
import LogoImg from './img/fashion-4th-floor-re-do.jpg';

import Home from './components/pages/Home';
import About from './components/pages/About';
import Post from './components/pages/Post';
import Category from './components/pages/Category';
import NotFound from './components/pages/NotFound';
import ScrollToTop from './components/helpers/ScrollToTop';
import AddThisRefresh from './components/helpers/AddThisRefresh';
import Page from './components/pages/Page';
import {useLoadingById} from './components/data/Store';

const headerHeight = 60;

const AppContent = styled.div`
  min-height: ${props => props.theme.loading ? `calc(100vh - ${headerHeight}px)` : '200px'};
`;

const Logo = styled.div`
  background-color: #f8f8f8;
  padding: 40px 0;
  text-align: center;

  @media ${device.laptop} {
    padding: 40px 0 30px 0;
  }
`;

function App() {
  const [contentLoading] = useLoadingById('mainContent');

  return (
    <Router>
      <Normalize />
      <GlobalStyles />
      <TypographyStyles />
      <ScrollToTop />
      <AddThisRefresh />
      <Header height={headerHeight} />
      <AppContent theme={{loading: contentLoading}}>
        <Logo>
          <Link to="/">
            <img src={LogoImg} alt="Fashion On The 4th Floor Logo" />
          </Link>
        </Logo>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/page/:page" component={Home} />

          <Route exact path="/about" component={About} />
          <Route exact path="/category/:slug" component={Category} />
          <Route exact path="/category/:slug/page/:page" component={Category} />
          <Route path="/:year/:month/:day/:slug" component={Post} />
          <Route path="/:slug" component={Page} />
          <Route component={NotFound} />
        </Switch>
      </AppContent>
      <InstagramFeed />
      <Footer />
    </Router>
  );
}

export default App;
