import React from 'react';
import PropTypes from 'prop-types';
import styled, {keyframes} from 'styled-components';

import Container from '../layout/Container';

const pulse = keyframes`
  0% {opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {opacity: 0.0;}
`;

const LoadingEl = styled.div`
  animation: ${pulse} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5; 
  background-color: #fff;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
  height: ${props => props.height}px;
  margin: 50px 0;
`;

const Loading = ({height}) => (
  <Container>
    <LoadingEl height={height} />
  </Container>
);

Loading.propTypes = {
  height: PropTypes.number
};

Loading.defaultProps = {
  height: 350
};

export default Loading;
