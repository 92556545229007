import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  margin: 0 auto;
  max-width: 1080px;
  padding: 0 15px;
`;

export default ({children, className}) => <Wrap className={className}>{children}</Wrap>;
