import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {click} from '../../utils/componentHelpers';
import {clearfix} from '../../styles/mixins';

const PaginationWrap = styled.div`
  ${clearfix()};
`;

const PageBtn = styled(Link)`
  background: #fff;
  padding: 20px;
  margin-bottom: 60px;
  text-transform: capitalize;
  display: inline;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
  text-transform: uppercase;
  font-size: 14px;
`;

const NewerBtn = styled(PageBtn)`
  float: left;
`;

const OlderBtn = styled(PageBtn)`
  float: right;
`;

const Pagination = ({nextText, prevText, onClick, currentPage, perPage, total, baseUrl}) => (
  <PaginationWrap>
    {currentPage <= 1 ? null : (
      <NewerBtn to={currentPage <= 2 ? baseUrl : `${baseUrl}/page/${currentPage - 1}`}>
        {prevText}
      </NewerBtn>
    )}
    {currentPage * perPage >= total ? null : (
      <OlderBtn to={`${baseUrl}/page/${currentPage === 0 ? 2 : currentPage + 1}`}>
        {nextText}
      </OlderBtn>
    )}
  </PaginationWrap>
);

Pagination.propTypes = {
  nextText: PropTypes.string,
  prevText: PropTypes.string,
  onClick: PropTypes.func,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number,
  baseUrl: PropTypes.string.isRequired
};

Pagination.defaultProps = {
  nextText: 'Older Posts >>',
  prevText: '<< Newer Posts'
};

export default Pagination;
