import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

// import Image from '../pieces/Image';

// const ImageRenderer = props => <Image wrapTag="span" {...props} style={{height: 'initial', width: 'initial'}} />;

const renderers = {
  // image: ImageRenderer
};

const Wrap = styled(ReactMarkdown)`
  ${props =>
    props.dropcap
      ? `
    p:first-of-type {
      &::first-letter {
        font-size: 86px !important;
        margin: 6px 10px 0 0!important;
        font-weight: 600;
        line-height: 76px !important;
        float: left;
      }
    }
  `
      : ''}

  a {
    color: #b565a7;
  }

  img {
    display: block;
    margin: 0 auto;
  }
`;

const Wysiwyg = ({content, className, dropcap}) => (
  <>
    {content ? (
      <Wrap dropcap={dropcap} className={className} source={content} escapeHtml={false} renderers={renderers} />
    ) : null}
  </>
);

Wysiwyg.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  dropcap: PropTypes.bool
};

export default Wysiwyg;
